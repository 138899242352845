import * as React from "react"
import { useEffect } from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { getNoveltieAction,getNoveltiesAction } from "../../../redux/noveltiesDucks"
import { graphql, useStaticQuery } from 'gatsby';
import {connect} from "react-redux"
import Detail from '../../../components/Novedad/main'
import { useGetDetailBlogQuery } from "../../../redux/mediacore/blog"
//Components

// const ClientSideOnlyLazy = React.lazy(() =>
// import('../../../components/Novedad/main')
// )


const IndexPage = (props) => {
  
  const {id} = props
  
  const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            env { 
              APISERVER
              TENANTID
              CLIENTID
            }
        }
  }`)

  const isSSR = typeof window === "undefined"

  const { data: BlogDetailData, isLoading: isLoadingDetailData } = useGetDetailBlogQuery({"CLIENTID": realEstate.env.CLIENTID, "BLOG_ID": id});

  return(
    <Layout novelty={BlogDetailData} preloader={true}>
      <article>
        <Detail id={id} />
      </article>
    </Layout>
  )
}

export default IndexPage